import React from "react";
import { connect } from "react-redux";
import {
  OFFER_DETAILS,
  ORDER_LIST,
  SELECT_ALL_OFFERS,
  REDIRECT,
} from "../../../constants/actionTypes";
import {
  dateTypes,
  ManagementOrderLinkList,
  OrderStatus,
  TabName,
} from "../../../constants/defaults";
import Pager from "../../../components/Pager";
import Loader from "../../../components/Loader";
import Content from "../../../components/containers/Content";
import OrderTabs from "../../../components/OrderTabs";
import Filter from "../../../components/Filter";
import OrderExtraData from "../../../components/OrderExtraData";
import { toast } from "react-toastify";
import { getLatestOrder } from "../../../functions/orderValues";
import Collapse from "../../../components/common/collapse/Collapse";
import CollapseHeader from "../../../components/common/collapse/CollapseHeader";
import CollapseBody from "../../../components/common/collapse/CollapseBody";
import OrderCollapseHeader from "./components/collapse/ManagementOrderCollapseHeader";
import OrderCollapseBody from "./components/collapse/ManagementOrderCollapseBody";
import BlankPage from "../../../components/BlankPage";
import base64 from "base-64";
import { Endpoints } from "../../../constants/endpoints";
import { Icons } from "../../../constants/icons";
import Button from "../../../components/common/Button";
import services from "../../../api/index";
import CheckPermission, {
  StaticPermission,
} from "../../../constants/permission";

const mapStateToProps = (state) => {
  return {
    order: state.order,
    router: state.router,
    report: state.report,
    currentUser: state.auth.currentUser,
    pageSize: state.common.pageSize,
    mypermission: state.permission.mypermission,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChangeCustomPrice: (value) =>
    dispatch({ type: OFFER_DETAILS, key: "customPrice", value }),
  onChangeDemandQuantity: (value) =>
    dispatch({ type: OFFER_DETAILS, key: "managementDemandQuantity", value }),
  onChangeDemandPrice: (value) =>
    dispatch({ type: OFFER_DETAILS, key: "managementDemandPrice", value }),
  ordersListOnLoad: (payload) => dispatch({ type: ORDER_LIST, payload }),
  onSelectOffer: (offer) => dispatch({ type: SELECT_ALL_OFFERS, offer }),
  onRedirect: (redirectTo) => dispatch({ type: REDIRECT, redirectTo }),
});

const TempStatusEnum = {
  Salesman: {
    sendToConfirmation: `${OrderStatus.Submitted},${OrderStatus.Confirmed},${OrderStatus.Rebidding},${OrderStatus.SalesmanApproved},${OrderStatus.Level1Approved}`,
    waitingMyApproval: `${OrderStatus.Submitted},${OrderStatus.Confirmed}`,
  },
  SuperAdmin: {
    sendToConfirmation: `${OrderStatus.Submitted},${OrderStatus.Confirmed},${OrderStatus.Rebidding},${OrderStatus.SalesmanApproved},${OrderStatus.Level1Approved}`,
    waitingMyApproval: `${OrderStatus.Submitted},${OrderStatus.Confirmed}`,
    salesmanApproved: `${OrderStatus.SalesmanApproved}`,
    level1Approved: `${OrderStatus.Level1Approved}`,
  },
  loaded: false,
};

let StatusEnum = {
  Salesman: {
    sendToConfirmation: `${OrderStatus.Submitted},${OrderStatus.Confirmed},${OrderStatus.Rebidding},${OrderStatus.SalesmanApproved},${OrderStatus.Level1Approved}`,
    waitingMyApproval: `${OrderStatus.Submitted},${OrderStatus.Confirmed}`,
  },
  SuperAdmin: {
    sendToConfirmation: `${OrderStatus.Submitted},${OrderStatus.Confirmed},${OrderStatus.Rebidding},${OrderStatus.SalesmanApproved},${OrderStatus.Level1Approved}`,
    waitingMyApproval: `${OrderStatus.Submitted},${OrderStatus.Confirmed}`,
    salesmanApproved: `${OrderStatus.SalesmanApproved}`,
    level1Approved: `${OrderStatus.Level1Approved}`,
  },
  loaded: false,
};

class Orders extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: props.match.params.filter
        ? JSON.parse(base64.decode(props.match.params.filter))
        : null,
      sortBy: props.match.params.sort,
      pageindex: props.match.params.page ? props.match.params.page : 0,
      statusList: this.getStatusesFromTab(),
      selectedTab: "send",
      collapseIsLoading: false,
      tabStatusCount: {},
      btnDisable: false,
      selectedItems: [],
    };

    this.approve = (
      orderid,
      processingStartUTC,
      processingEndUTC,
      dontCreateInvoice,
      isFastBot
    ) => {
      console.log({
        orderid,
        processingStartUTC,
        processingEndUTC,
        dontCreateInvoice,
        isFastBot,
      });
      this.setBtnDisable();
      services.orders
        .approve(
          orderid,
          processingStartUTC,
          processingEndUTC,
          dontCreateInvoice,
          isFastBot
        )
        .then(() => {
          this.setBtnEnable();
          toast.success(
            <div>Your transaction has been completed successfully.</div>
          );
          this.fixPageIndexWithOnFilter();
        })
        .catch((err) => {
          this.setBtnEnable();
          if (err.response?.data?.message)
            toast.error(<div>{err.response?.data.message}</div>);
        });
    };
    this.refuse = (orderid) => {
      this.setBtnDisable();

      services.orders
        .refuse(orderid)
        .then(() => {
          this.setBtnEnable();
          toast.success(
            <div>Your transaction has been completed successfully.</div>
          );
          this.fixPageIndexWithOnFilter();
        })
        .catch((err) => {
          this.setBtnEnable();
          if (err.response?.data?.message)
            toast.error(<div>{err.response?.data.message}</div>);
        });
    };
    this.rebid = (quantity, price, orderid) => {
      this.setBtnDisable();
      services.orders
        .rebid(quantity, price, orderid)
        .then(() => {
          this.setBtnEnable();
          toast.success(
            <div>Your transaction has been completed successfully.</div>
          );
          this.fixPageIndexWithOnFilter();
        })
        .catch((err) => {
          this.setBtnEnable();
          if (err.response?.data?.message)
            toast.error(<div>{err.response?.data.message}</div>);
        });
    };
    this.revoke = (orderid) => {
      this.setBtnDisable();
      services.orders
        .revoke(orderid)
        .then(() => {
          this.setBtnEnable();
          toast.success(
            <div>Your transaction has been completed successfully.</div>
          );
          this.fixPageIndexWithOnFilter();
        })
        .catch((err) => {
          this.setBtnEnable();
          if (err.response?.data?.message)
            toast.error(<div>{err.response?.data.message}</div>);
        });
    };
    this.close = (orderid, SendBackOrderedItemsToInventory) => {
      this.setBtnDisable();
      services.orders
        .close(orderid, SendBackOrderedItemsToInventory)
        .then(() => {
          this.setBtnEnable();
          toast.success(
            <div>Your transaction has been completed successfully.</div>
          );
          this.fixPageIndexWithOnFilter();
        })
        .catch((err) => {
          this.setBtnEnable();
          if (err.response?.data?.message)
            toast.error(<div>{err.response?.data.message}</div>);
        });
    };
    this.refund = (orderid) => {
      this.setBtnDisable();
      services.orders
        .refundManagement(orderid)
        .then(() => {
          this.setBtnEnable();
          toast.success(
            <div>Your transaction has been completed successfully.</div>
          );
          this.fixPageIndexWithOnFilter();
        })
        .catch((err) => {
          this.setBtnEnable();
          if (err.response?.data?.message)
            toast.error(<div>{err.response?.data.message}</div>);
        });
    };

    this.createInvoice = (orderid) => {
      this.setBtnDisable();
      services.orders
        .createInvoice(orderid)
        .then((res) => {
          this.setBtnEnable();
          toast.success(
            <div>Your transaction has been completed successfully.</div>
          );
        })
        .catch((err) => {
          this.setBtnEnable();
          if (err.response?.data?.message)
            toast.error(<div>{err.response?.data.message}</div>);
        });
    };
    this.reprocess = (orderid) => {
      this.setBtnDisable();
      services.orders
        .reprocess(orderid)
        .then((res) => {
          this.setBtnEnable();
          toast.success(
            <div>Your transaction has been completed successfully.</div>
          );
          this.fixPageIndexWithOnFilter();
        })
        .catch((err) => {
          this.setBtnEnable();
          if (err.response?.data?.message)
            toast.error(<div>{err.response?.data.message}</div>);
        });
    };

    this.pause = (orderid) => {
      this.setBtnDisable();
      services.orders
        .pause(orderid)
        .then((res) => {
          this.setBtnEnable();
          toast.success(
            <div>Your transaction has been completed successfully.</div>
          );
          this.fixPageIndexWithOnFilter();
        })
        .catch((err) => {
          this.setBtnEnable();
          if (err.response?.data?.message)
            toast.error(<div>{err.response?.data.message}</div>);
        });
    };

    this.sendToInventory = (orderid, params) => {
      this.setBtnDisable();
      services.orders
        .sendToInventory(orderid, params)
        .then((res) => {
          this.setBtnEnable();
          toast.success(
            <div>Your transaction has been completed successfully.</div>
          );
          this.fixPageIndexWithOnFilter();
        })
        .catch((err) => {
          this.setBtnEnable();
          if (err.response?.data?.message)
            toast.error(<div>{err.response?.data.message}</div>);
        });
    };
  }

  fixPageIndexWithOnFilter() {
    this.onFilter({
      pageindex: this.state.pageIndex,
    });
  }

  shouldComponentUpdate() {
    if (
      this.props.match.params.tabName &&
      this.state.selectedTab === undefined
    ) {
      this.setTab();
    }
    return true;
  }

  componentDidUpdate(prevProps) {
    if (this.props.pageSize !== prevProps.pageSize) {
      this.onFilter({ pageindex: 0 });
    }
    if (this.props.match.params.tabName !== prevProps.match.params.tabName) {
      this.setTab();
    }
    if (this.props.mypermission && !StatusEnum.loaded) {
      if (
        CheckPermission(
          StaticPermission.Order.Level1Approve,
          this.props.mypermission
        )
      ) {
        StatusEnum[
          this.props.currentUser?.scope[0]
        ].waitingMyApproval = `${OrderStatus.SalesmanApproved}`;
        StatusEnum.loaded = true;
        this.selectOrders();
      }

      if (
        CheckPermission(
          StaticPermission.Order.Level2Approve,
          this.props.mypermission
        )
      ) {
        StatusEnum[
          this.props.currentUser?.scope[0]
        ].waitingMyApproval = `${OrderStatus.Level1Approved}`;

        StatusEnum.loaded = true;
        this.selectOrders();
      }
    }
  }

  componentWillUnmount() {
    StatusEnum = TempStatusEnum;
  }

  setTab() {
    this.setState({ selectedTab: this.tabKeyControl() });
  }

  componentDidMount() {
    document.title = "Orders";
    if (this.props.match.params.tabName) {
      this.setState({ selectedTab: this.props.match.params.tabName });
    }
    this.getOrders();
  }

  selectOrders() {
    let ev = this.state.filter;

    this.getOrders({
      partnerIds: ev?.PartnerId?.length !== 0 ? ev?.PartnerId?.join(",") : "",
      keywords: ev?.Text,
      priceTo: ev?.Price?.up,
      priceFrom: ev?.Price?.down,
      createdAtFrom: ev?.DateRange?.min,
      createdAtTo: ev?.DateRange?.max,
      productPublisher: ev?.PublishersName?.join(","),
      states:
        ev?.Status?.length !== 0 && ev !== null
          ? ev?.Status?.join(",")
          : this.getStatusesFromTab(),
      sortBy: this.state.sortBy,
      pageindex: this.state.pageindex,
      IsBilled:
        ev?.InvoicedStatus !== "" && ev?.InvoicedStatus !== undefined
          ? ev?.InvoicedStatus === "1"
            ? true
            : false
          : "",
      IsDelivered:
        ev?.DeliveredStatus !== "" && ev?.DeliveredStatus !== undefined
          ? ev?.DeliveredStatus === "1"
            ? true
            : false
          : "",
      ProviderIds: ev?.Providers?.join(","),
    });
  }

  getStatusesFromTab() {
    return this.props?.match.params.tabName === "send"
      ? StatusEnum[this.props.currentUser?.scope[0]]?.sendToConfirmation
      : this.props?.match.params.tabName === "waiting"
      ? StatusEnum[this.props.currentUser?.scope[0]]?.waitingMyApproval
      : this.props?.match.params.tabName === "salesman"
      ? StatusEnum[this.props.currentUser?.scope[0]]?.salesmanApproved
      : this.props?.match.params.tabName === "level1approved"
      ? StatusEnum[this.props.currentUser?.scope[0]]?.level1Approved
      : this.props?.match.params.tabName === TabName.Management.allOrders
      ? ""
      : StatusEnum[this.props.currentUser?.scope[0]]?.sendToConfirmation;
  }

  onFilter(params) {
    let {
      filter = null,
      sortBy = undefined,
      pageindex = this.state.pageindex,
      states = undefined,
    } = params || {};
    let ev = filter;

    states = states ?? this.state.statusList;
    this.setState({ statusList: states });

    if (ev) {
      pageindex = 0;
      this.setState({
        filter: ev,
      });
    } else ev = this.state.filter;

    let url = Endpoints.Management.Orders.url
      .replace(":tabName?", this.props?.match.params.tabName)
      .replace(":filter?", base64.encode(ev ? JSON.stringify(ev) : "0"))
      .replace(":sort?", sortBy ? sortBy : "0")
      .replace(":page?", pageindex);

    this.props.onRedirect(url);
    if (sortBy !== undefined) {
      if (sortBy === this.state.sortBy) sortBy = `-${sortBy}`;
      this.setState({ sortBy });
    } else sortBy = this.state.sortBy;

    this.getOrders({
      partnerIds: ev?.PartnerId?.length !== 0 ? ev?.PartnerId?.join(",") : "",
      keywords: ev?.Text,
      priceTo: ev?.Price?.up,
      priceFrom: ev?.Price?.down,
      createdAtFrom: ev?.DateRange?.min,
      createdAtTo: ev?.DateRange?.max,
      productPublisher: ev?.PublishersName?.join(","),
      states: states
        ? states
        : ev?.Status?.join(",")
        ? ev?.Status?.join(",")
        : "",
      sortBy: sortBy,
      pageindex: pageindex,
      IsBilled:
        ev?.InvoicedStatus !== "" && ev?.InvoicedStatus !== undefined
          ? ev?.InvoicedStatus === "1"
            ? true
            : false
          : "",
      IsDelivered:
        ev?.DeliveredStatus !== "" && ev?.DeliveredStatus !== undefined
          ? ev?.DeliveredStatus === "1"
            ? true
            : false
          : "",
      providerIds: ev?.Providers?.join(","),
    });
  }

  getOrders(params) {
    this.setState({ collapseIsLoading: true });
    const {
      pageindex = this.state.pageindex,
      pagesize = this.props.pageSize,
      states = this.state.statusList,
      keywords,
      productSku,
      priceFrom,
      priceTo,
      createdAtFrom,
      createdAtTo,
      sortBy,
      IsBilled = "",
      partnerIds = "",
      IsDelivered = "",
      productPublisher = "",
      providerIds = "",
    } = params || {};

    this.setState({ pageindex: pageindex });
    this.props.ordersListOnLoad(
      Promise.all([
        services.orders.getManagementOrders(
          pageindex,
          pagesize,
          states,
          keywords,
          productSku,
          priceFrom,
          priceTo,
          createdAtFrom,
          createdAtTo,
          sortBy === "0" ? "" : sortBy,
          IsBilled,
          partnerIds,
          IsDelivered,
          productPublisher,
          providerIds
        ),
      ])
        .then((res) => {
          this.setState({
            showCollapse:
              res[0].Items.filter((f) => f.Id === this.state.selectedItemId)
                .length > 0
                ? true
                : this.state.showCollapse,
            collapseIsLoading: false,
            pageindex: pageindex,
          });
          if (this.state.selectedTab) {
            this.getOrderStatus();
          }
          return res[0];
        })
        .catch((err) => {
          if (err.response?.data?.message)
            toast.error(<div>{err.response?.data.message}</div>);
          return undefined;
        })
    );
  }

  getOrderStatus() {
    Promise.all([services.report.getOrderStatus()])
      .then((res) => {
        res = res[0];

        let tabStatusCountValues = [];
        tabStatusCountValues.sendToConfirmation = res
          .filter((f) =>
            StatusEnum[this.props.currentUser?.scope[0]]?.sendToConfirmation
              ?.split(",")
              .includes(f.Status)
          )
          .map((item) => item.Count)
          .reduce((a, b) => a + b, 0);

        tabStatusCountValues.waitingMyApproval = res
          .filter((f) =>
            StatusEnum[this.props.currentUser?.scope[0]]?.waitingMyApproval
              ?.split(",")
              .includes(f.Status)
          )
          .map((item) => item.Count)
          .reduce((a, b) => a + b, 0);

        tabStatusCountValues.salesmanApproved = res
          .filter((f) =>
            StatusEnum[this.props.currentUser?.scope[0]]?.salesmanApproved
              ?.split(",")
              .includes(f.Status)
          )
          .map((item) => item.Count)
          .reduce((a, b) => a + b, 0);

        tabStatusCountValues.level1Approved = res
          .filter((f) =>
            StatusEnum[this.props.currentUser?.scope[0]]?.level1Approved
              ?.split(",")
              .includes(f.Status)
          )
          .map((item) => item.Count)
          .reduce((a, b) => a + b, 0);

        tabStatusCountValues.allOrders = res
          .map((item) => item.Count)
          .reduce((a, b) => a + b, 0);

        console.log("tab count status");
        console.log(
          StatusEnum[this.props.currentUser?.scope[0]]?.waitingMyApproval
        );
        this.setState({ tabStatusCount: tabStatusCountValues });
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
        return undefined;
      });
  }

  onChangePageIndex(pageindex) {
    let key = this.state.selectedTab;
    let states = "";
    if (key === TabName.Management.sendToConfirmation) {
      states = StatusEnum[this.props.currentUser?.scope[0]]?.sendToConfirmation;
    } else if (key === TabName.Management.waitingMyApproval) {
      states = StatusEnum[this.props.currentUser?.scope[0]]?.waitingMyApproval;
    } else if (key === TabName.Management.salesmanApproved) {
      states = StatusEnum[this.props.currentUser?.scope[0]]?.salesmanApproved;
    }

    this.onFilter({
      states: states,
      pageindex: pageindex,
    });
  }

  selectOffer(offer) {
    this.props.onChangeCustomPrice(false);
    const latestOrder = getLatestOrder(offer.History, false);
    this.props.onChangeDemandQuantity(latestOrder?.OfferQuantity);
    this.props.onChangeDemandPrice(latestOrder?.OfferPrice);
    this.props.onSelectOffer(offer);
  }

  setTabKey(key) {
    let tabName =
      key === TabName.Management.sendToConfirmation
        ? "send"
        : key === TabName.Management.waitingMyApproval
        ? "waiting"
        : key === TabName.Management.salesmanApproved
        ? "salesman"
        : key === TabName.Management.level1Approved
        ? "level1approved"
        : TabName.Management.allOrders;

    this.setState({
      statusList:
        key === TabName.Management.waitingMyApproval
          ? StatusEnum[this.props.currentUser?.scope[0]]?.waitingMyApproval
          : key === TabName.Management.sendToConfirmation
          ? StatusEnum[this.props.currentUser?.scope[0]]?.sendToConfirmation
          : key === TabName.Management.salesmanApproved
          ? StatusEnum[this.props.currentUser?.scope[0]]?.salesmanApproved
          : key === TabName.Management.level1Approved
          ? StatusEnum[this.props.currentUser?.scope[0]]?.level1Approved
          : undefined,
    });

    if (this.props?.match.params.tabName === tabName) return;

    let filter = this.state.filter;
    if (filter) filter.Status = [];

    this.setState({ selectedTab: key });

    if (key === TabName.Management.sendToConfirmation) {
      this.onFilter({
        states:
          StatusEnum[this.props.currentUser?.scope[0]]?.sendToConfirmation,
        pageindex: 0,
      });
    } else if (key === TabName.Management.waitingMyApproval) {
      this.onFilter({
        states: StatusEnum[this.props.currentUser?.scope[0]]?.waitingMyApproval,
        pageindex: 0,
      });
    } else if (key === TabName.Management.salesmanApproved) {
      this.onFilter({
        states: StatusEnum[this.props.currentUser?.scope[0]]?.salesmanApproved,
        pageindex: 0,
      });
    } else if (key === TabName.Management.level1Approved) {
      this.onFilter({
        states: StatusEnum[this.props.currentUser?.scope[0]]?.level1Approved,
        pageindex: 0,
      });
    } else {
      this.onFilter({
        states: "",
        pageindex: 0,
      });
    }
    let url = Endpoints.Management.Orders.url
      .replace(":tabName", tabName)
      .replace(":filter?", base64.encode(filter ? JSON.stringify(filter) : "0"))
      .replace(":sort?", this.state.sortBy ? this.state.sortBy : "0")
      .replace(":page?", this.state.pageindex);
    this.props.onRedirect(url);
  }

  tabKeyControl() {
    console.log(this.props?.match.params.tabName);
    if (this.state.selectedTab === "send") {
      return TabName.Management.sendToConfirmation;
    } else
      switch (this.props?.match.params.tabName) {
        case "send":
          return TabName.Management.sendToConfirmation;
        case "waiting":
          return TabName.Management.waitingMyApproval;
        case "salesman":
          return TabName.Management.salesmanApproved;
        case "level1approved":
          return TabName.Management.level1Approved;
        default:
          return TabName.Management.allOrders;
      }
  }

  CollapseShow(itemId) {
    this.setState({
      showCollapse:
        itemId === this.state.selectedItemId ? !this.state.showCollapse : true,
      selectedItemId: itemId,
    });
  }

  setBtnEnable() {
    this.setState({ btnDisable: false });
  }

  setBtnDisable() {
    this.setState({ btnDisable: true });
  }

  render() {
    const { orderList } = this.props.order;
    return (
      <Content>
        <div className="d-flex flex-row">
          <h1>Orders</h1>
          <Button
            className="bulk_buttons mx-3 px-4"
            label="Bulk Order"
            bindEvent={() =>
              this.props.onRedirect(Endpoints.Management.BulkOrder.url)
            }
            iconAlign="right"
            buttonIcon={Icons.nullIcon}
          />
        </div>
        {orderList === undefined ? (
          <Loader />
        ) : (
          <div>
            <OrderTabs
              orderList={ManagementOrderLinkList}
              setTabKey={(_key) => this.setTabKey(_key)}
              activeKey={this.tabKeyControl()}
              tabStatusCount={this.state.tabStatusCount}
              userRole={this.props.currentUser?.scope}
            />
            <Filter
              filter={this.state.filter}
              onFilter={(params) => {
                let { filter, sortBy } = params || {};
                this.onFilter({ filter: filter, sortBy: sortBy });
              }}
              isPartnerAvailable
              isInvoicedAvailable
              isDeliveredAvailable
              isPublisherAvailable
              isPublisherSelectSingle
              isProviderAvailable
              priceList={[
                { down: undefined, up: 25 },
                { down: 25, up: 50 },
                { down: 50, up: 100 },
                { down: 100, up: 200 },
                { down: 300, up: undefined },
              ]}
              dateList={[
                dateTypes.ALLTIME,
                dateTypes.LASTMONTH,
                dateTypes.THISMONTH,
              ]}
              statusList={[
                OrderStatus.Completed,
                OrderStatus.Confirmed,
                OrderStatus.Closed,
                OrderStatus.Cancelled,
                OrderStatus.Failed,
                OrderStatus.Processing,
                OrderStatus.Paused,
                OrderStatus.Refused,
                OrderStatus.Refund,
                OrderStatus.Refunded,
                OrderStatus.Rejected,
                OrderStatus.Rebidding,
                OrderStatus.Revoked,
                OrderStatus.Submitted,
                OrderStatus.Withdraw,
                OrderStatus.Transferred,
                OrderStatus.SalesmanApproved,
                OrderStatus.Level1Approved,
                OrderStatus.Level2Approved,
                OrderStatus.AdminRefused,
                OrderStatus.OutOfStock,
                OrderStatus.PromotionExpired,
              ]}
              statusVisible={this.state.selectedTab === "allOrders"}
            />
            <OrderExtraData data={orderList?.ExtraData} />
            {this.state.collapseIsLoading ? (
              <Loader />
            ) : orderList?.Items?.length === 0 ? (
              <BlankPage description="There is no order." />
            ) : (
              <div>
                {orderList?.Items?.map((item, index) => {
                  return (
                    <Collapse item={item} index={index} key={index}>
                      <CollapseHeader>
                        <OrderCollapseHeader
                          collapseShow={() => this.CollapseShow(item.Id)}
                          item={item}
                          filter={this.state.filter}
                          showCollapse={this.state.showCollapse}
                          selectedItemId={this.state.selectedItemId}
                          tabName={this.state.selectedTab}
                          selectedItems={this.state.selectedItems}
                          changeSelectedItem={(id) => {
                            if (this.state.selectedItems.includes(id)) {
                              this.setState({
                                selectedItems: this.state.selectedItems.filter(
                                  (f) => f !== id
                                ),
                              });
                            } else {
                              let selectedItems = this.state.selectedItems;
                              selectedItems.push(id);
                              this.setState({
                                selectedItems: selectedItems,
                              });
                            }
                          }}
                        />
                      </CollapseHeader>
                      <CollapseBody>
                        {item.Id === this.state.selectedItemId &&
                        this.state.showCollapse ? (
                          <OrderCollapseBody
                            btnDisable={this.state.btnDisable}
                            item={item}
                            history={item.History}
                            rebid={(_demandQuantity, _demandPrice, _orderId) =>
                              this.rebid(
                                _demandQuantity,
                                _demandPrice,
                                _orderId
                              )
                            }
                            approve={(
                              _orderId,
                              _processingStartUTC,
                              _processingEndUTC,
                              _dontCreateInvoice,
                              _isFastBot
                            ) =>
                              this.approve(
                                _orderId,
                                _processingStartUTC,
                                _processingEndUTC,
                                _dontCreateInvoice,
                                _isFastBot
                              )
                            }
                            refuse={(_orderId) => this.refuse(_orderId)}
                            revoke={(_orderId) => this.revoke(_orderId)}
                            refund={(_orderId) => this.refund(_orderId)}
                            close={(
                              _orderId,
                              _SendBackOrderedItemsToInventory
                            ) =>
                              this.close(
                                _orderId,
                                _SendBackOrderedItemsToInventory
                              )
                            }
                            createInvoice={(_orderId) =>
                              this.createInvoice(_orderId)
                            }
                            reprocess={(_orderId) => this.reprocess(_orderId)}
                            pause={(_orderId) => this.pause(_orderId)}
                            sendToInventory={(_orderId, params) => {
                              this.sendToInventory(_orderId, params);
                            }}
                          ></OrderCollapseBody>
                        ) : (
                          <></>
                        )}
                      </CollapseBody>
                    </Collapse>
                  );
                })}
                {this.state.selectedTab ===
                  TabName.Management.salesmanApproved && (
                  <li className="form-check float-end px-0 mt-2">
                    <input
                      id="select_all"
                      type="checkbox"
                      className="m-2"
                      checked={
                        this.state.selectedItems.length ===
                        orderList.Items.length
                      }
                      onChange={(ev) => {
                        if (ev.target.checked) {
                          this.setState({
                            selectedItems: orderList.Items.map((f) => f.Id),
                          });
                        } else
                          this.setState({
                            selectedItems: [],
                          });
                      }}
                    ></input>
                    <label htmlFor="select_all">Select All</label>
                  </li>
                )}
                <Button
                  className="confirm-button px-4 mb-1 float-end"
                  bindEvent={() => {
                    services.orders
                      .approveAll(this.state.selectedItems)
                      .then((response) => {
                        this.fixPageIndexWithOnFilter();
                        toast.success(
                          <div>Orders have been successfully confirmed.</div>
                        );
                        this.setState({
                          selectedItems: [],
                        });
                        this.onChangePageIndex(0);
                      })
                      .catch((err) => {
                        if (err.response?.data?.message)
                          toast.error(<div>{err.response?.data.message}</div>);
                      });
                  }}
                  visible={
                    this.state.selectedItems.length > 0 &&
                    (this.state.selectedTab !=
                      TabName.Management.waitingMyApproval ||
                      this.state.selectedTab !=
                        TabName.Management.SalesmanApproved)
                  }
                  label="Approve All"
                  buttonIcon={Icons.thickIcon}
                  iconWidth={17}
                  iconHeight={17}
                />
                <Pager
                  pageIndex={orderList?.PageIndex}
                  totalPages={orderList?.TotalPages}
                  totalCount={orderList?.TotalCount}
                  onChange={(pageIndex) => {
                    this.onChangePageIndex(pageIndex);
                  }}
                />
              </div>
            )}
          </div>
        )}
      </Content>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Orders);

// eslint-disable-next-line import/prefer-default-export
export const Endpoints = {
  Management: {
    Home: {
      url: "/management/index",
      subMenuText: "Home",
    },
    Promotions: {
      url: "/management/promotions/:filter?/:sort?/:page?",
      subMenuText: "All Promotions",
    },
    NewPromotions: {
      url: "/management/new-promotions",
      subMenuText: "New Promotions",
    },
    EditPromotion: {
      url: "/management/edit-promotion/:promotionid",
      subMenuText: "Edit Promotion",
    },
    Orders: {
      url: "/management/orders/:tabName?/:filter?/:sort?/:page?",
      subMenuText: "Orders",
    },
    BulkOrder: {
      url: "/management/bulk-order",
      subMenuText: "Bulk Order",
    },
    OrderDetail: {
      url: "/management/order-detail/:orderid",
      subMenuText: "Order Details",
    },
    PromotionDetail: {
      url: "/management/promotion-detail/:promotionid",
      subMenuText: "Promotion Detail",
    },
    Invoices: {
      url: "/management/invoices/:filter?/:sort?/:page?",
      subMenuText: "Invoices",
    },
    UnInvoicedOrders: {
      url: "/management/uninvoiced/:filter?/:sort?/:page?",
      subMenuText: "Uninvoiced Orders",
    },
    CreditNotes: {
      url: "/management/credit-notes/:filter?/:sort?/:page?",
      subMenuText: "Credit Notes",
    },
    Inventories: {
      url: "/management/inventories",
      subMenuText: "Inventories",
    },
    UpcomingPromotions: {
      url: "/management/upcoming-promotions",
      subMenuText: "Upcoming Promotions",
    },
    UpcomingProviderPromotions: {
      url: "/management/genba-provider-promotions",
      subMenuText: "Provider Promotions",
    },
    ProviderProducts: {
      url: "/management/provider-products/:filter?/:sort?/:page?",
      subMenuText: "Provider Products",
    },
    UpcomingProducts: {
      url: "/management/upcoming-promotion-products/:filter?/:sort?/:page?",
      subMenuText: "Upcoming Promotion Products",
    },
    Allocations: {
      url: "/management/allocations",
      subMenuText: "Allocations",
    },
    CreateAllocation: {
      url: "/management/create-allocation",
      subMenuText: "Create Allocation",
    },
    Companies: {
      url: "/management/companies",
      subMenuText: "Companies",
    },
    Users: {
      url: "/management/users",
      subMenuText: "Users",
    },
    EditUser: {
      url: "/management/edit-user/:userid?",
      subMenuText: "Edit User",
    },
    CreditLimits: {
      url: "/management/credit-limits",
      subMenuText: "Credit Limits",
    },
    TopSellers: {
      url: "/management/top-sellers/:filter?/:page?",
      subMenuText: "Top Sellers",
    },
    MonthlyRevenue: {
      url: "/management/monthly-revenue/:filter?/:page?",
      subMenuText: "Monthly Revenue",
    },
    OrderProcessByStatus: {
      url: "/management/order-process/:page?",
      subMenuText: "Order Process",
    },
    ProductReport: {
      url: "/management/product-report/:filter?",
      subMenuText: "Product Report",
    },
    PermissionGroup: {
      url: "/management/permission-group",
      subMenuText: "Permission Group",
    },
    InventoryReports: {
      url: "/management/inventory-reports",
      subMenuText: "Inventory Reports",
    },
    InventoryReportsManagement: {
      url: "/management/inventory-reports-management",
      subMenuText: "Inventory Reports",
    },
    Vendors: {
      url: "/management/vendors",
      subMenuText: "Vendors",
    },
    ManuelStock: {
      url: "/management/manual-inventory",
      subMenuText: "Manuel Inventory",
    },
    CompanyAccount: {
      url: "/management/account/:partnerid",
      subMenuText: "Company Account",
    },
    Settings: {
      url: "/management/settings",
      subMenuText: "Settings",
    },
    MerkurRegions: {
      url: "/management/merkur-regions",
      subMenuText: "Merkur Regions",
    },
  },

  Partner: {
    Basket: {
      url: "/basket",
      subMenuText: "Basket",
    },
    Home: {
      url: "/promotions",
      subMenuText: "Home",
    },
    AllPromotions: {
      url: "/promotions/:filter?/:sort?/:page?",
      subMenuText: "All Promotions",
    },
    BulkPromotion: {
      url: "/active-promotions",
      subMenuText: "Active Promotions",
    },
    DirectSales: {
      url: "/directsales/:filter?/:sort?/:page?",
      subMenuText: "Direct Publisher",
    },
    MyOrders: {
      url: "/orders/:tabName/:filter?/:sort?/:page?",
      subMenuText: "My Orders",
    },
    MyInvoices: {
      url: "/invoices/:filter?/:sort?/:page?",
      subMenuText: "My Invoices",
    },
    MyUnInvoiceOrders: {
      url: "/uninvoiced/:filter?/:sort?/:page?",
      subMenuText: "My Uninvoiced Orders",
    },
    UpcomingPromotionsPartner: {
      url: "/upcoming-promotions",
      subMenuText: "Upcoming Promotions",
    },
    MyCreditLimit: {
      url: "/credit-limit",
      subMenuText: "My Credit Limit",
    },
    MyAccount: {
      url: "/myaccount",
      subMenuText: "My Account",
    },
  },
};

import React from "react";
import { connect } from "react-redux";
import {
  MANAGEMENT_CREATE_PROMOTION_PRODUCT,
  MANAGEMENT_PROMOTION_UPDATE_FIELD,
} from "../../../../../constants/actionTypes";
import {
  defaultDateFormat,
  PromotionPopupSearchInput,
} from "../../../../../constants/defaults";
import PromotionHeader from "../../../../../components/common/PromotionHeader";
import Loader from "../../../../../components/Loader";
import moment from "moment";
import Pager from "../../../../../components/Pager";
import { Icons } from "../../../../../constants/icons";
import Filter from "../../../../../components/Filter";
import { toast } from "react-toastify";
import services from "../../../../../api/index";

const mapDispatchToProps = (dispatch) => ({
  productsOnLoad: (payload) =>
    dispatch({ type: MANAGEMENT_CREATE_PROMOTION_PRODUCT, payload }),

  onChangeProductSearchText: (value) =>
    dispatch({
      type: MANAGEMENT_PROMOTION_UPDATE_FIELD,
      key: "searchProductText",
      value,
    }),

  onSelectProduct: (payload) => {
    dispatch({
      type: MANAGEMENT_PROMOTION_UPDATE_FIELD,
      key: "selectedProduct",
      value: payload,
    });
    dispatch({
      type: MANAGEMENT_PROMOTION_UPDATE_FIELD,
      key: "selectedRegion",
      value: [],
    });
  },
  onProductAlreadyCreated: (payload) => {
    dispatch({
      type: MANAGEMENT_PROMOTION_UPDATE_FIELD,
      key: "isProductAlreadyCreated",
      value: payload,
    });
  },
  onSetOnlyActivePromotions: (payload) => {
    dispatch({
      type: MANAGEMENT_PROMOTION_UPDATE_FIELD,
      key: "OnlyActivePromotions",
      value: payload,
    });
  },
});

const mapStateToProps = (state) => {
  return {
    promotions: state.promotions,
    isAuthenticated: state.auth.isAuthenticated,
  };
};

class SelectGame extends React.Component {
  constructor(props) {
    super(props);
    this.filterProducts();
    this.state = {
      isLoading: true,
      filter: undefined,
    };
  }
  filterProducts = (params) => {
    this.setState({ isLoading: true });
    const {
      OnlyActivePromotions = this.props.promotions.OnlyActivePromotions,
      store = "",
      pageindex = 0,
    } = params || {};
    services.product
      .getAllProductFromManagement(
        this.props.promotions.searchProductText,
        pageindex,
        10,
        "",
        OnlyActivePromotions,
        store,
        true
      )
      .then((payload) => {
        this.props.productsOnLoad(payload);
        this.setState({ isLoading: false });
      })
      .catch((err) => {});

    this.timer = null;
  };

  filterProductsWithText = (searchText) => {
    this.props.onChangeProductSearchText(searchText);
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.setState({ isLoading: true });
      this.filterProducts();
    }, 1000);
  };

  productSelection = (item) => {
    this.props.onSelectProduct(item);
    services.campaigns
      .isProductAlreadyCreated(item.Id)
      .then(() => {
        this.props.onProductAlreadyCreated(false);
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
        this.props.onProductAlreadyCreated(true);
      });
  };

  render() {
    return (
      <div>
        <div className="text-center mt-5 mb-2">
          <PromotionHeader title="Select Game" />
        </div>
        <div>
          <div className="row">
            <div className="col-md-9 ps-0">
              <div className="input-group">
                <span className="input-group-text" id="basic-addon1">
                  <img
                    className="ms-1"
                    src={Icons.promotionSearchIcon}
                    width="100"
                  ></img>
                </span>
                <input
                  type="text"
                  id="promotion-search-input"
                  placeholder={PromotionPopupSearchInput}
                  aria-label="Search"
                  onChange={(ev) => {
                    this.filterProductsWithText(ev.target.value);
                  }}
                  value={this.props.promotions.searchProductText}
                  onKeyDown={(ev) => {
                    if (ev.keyCode === 13) this.filterProducts();
                  }}
                />
              </div>
            </div>
            <div className="select-game-filter col-md-3 col-xs-12 ps-0">
              <Filter
                filter={this.state.filter}
                onFilter={(ev) =>
                  this.filterProducts({ store: ev.filter.Store })
                }
                isStoreAvailable
                hideSearch
              />
            </div>
          </div>
          <div className="terms-check mb-2">
            <input
              className="form-check-input"
              type="checkbox"
              id="isActive"
              onChange={(ev) => {
                this.props.onSetOnlyActivePromotions(ev.target.checked);
                this.filterProducts({
                  OnlyActivePromotions: ev.target.checked,
                });
              }}
            />
            <label className="terms-check-label" htmlFor="isActive">
              Show only products in promotion by publisher or distributor
            </label>
          </div>
          {this.state.isLoading ? (
            <div className="d-flex justify-content-center align-items-center mt-9 mb-9">
              <Loader />
            </div>
          ) : (
            <div>
              <div className="table-responsive col-md-12">
                <table className="table table-centered table-nowrap mb-0 rounded ">
                  <thead className="">
                    <tr className="border-0 table-sub-title">
                      <th></th>
                      <th className="w-25 ps-0">Name</th>
                      <th className="ps-0">Region</th>
                      <th className="ps-0">Store</th>
                      <th className="ps-0">Provider</th>
                      <th className="ps-0">SKU</th>
                      <th className="ps-0">Start Date</th>
                      <th className="ps-0">End Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(this.props.promotions.products
                      ? this.props.promotions.products
                      : undefined
                    )?.Items.map((item, key, arr) => {
                      return (
                        <tr
                          onClick={() => this.productSelection(item)}
                          key={key}
                          className={
                            item.Id ===
                            this.props.promotions.selectedProduct?.Id
                              ? "bg-gray-200"
                              : ""
                          }
                        >
                          <td>
                            <input
                              className="form-check-input"
                              type="radio"
                              id={item.Id}
                              name="products"
                              value={item.Id}
                              checked={
                                this.props.promotions.selectedProduct?.Id ===
                                item.Id
                              }
                              onChange={() => this.productSelection(item)}
                            ></input>
                          </td>
                          <td className="ps-0">{item.Name}</td>
                          <td className="ps-0">{item.Region}</td>
                          <td className="ps-0">{item.Store}</td>
                          <td className="ps-0">{item.Provider.Name}</td>
                          <td className="ps-0">{item.Sku}</td>
                          <td className="ps-0">
                            {item.ValidFrom
                              ? moment(item.ValidFrom).format(
                                  defaultDateFormat + "\t-\tH:mm:ss"
                                )
                              : " -"}
                          </td>
                          <td className="ps-0">
                            {item.ValidTo
                              ? moment(item.ValidTo).format(
                                  defaultDateFormat + "\t-\tH:mm:ss"
                                )
                              : " -"}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="row">
                <Pager
                  pageIndex={this.props.promotions.products?.PageIndex}
                  totalPages={this.props.promotions.products?.TotalPages}
                  totalCount={this.props.promotions?.TotalCount}
                  onChange={(pageIndex) => this.onChangePageIndex(pageIndex)}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  onChangePageIndex(pageindex) {
    this.setState({ isLoading: true });
    this.filterProducts({ pageindex });
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectGame);

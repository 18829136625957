export default (req) => ({
  getRegions: () => req.get(`/MerkurRegion/GetAll`),
  getFilterable: (Keywords, PageIndex, PageSize) =>
    req.get(`/MerkurRegion/GetFilterable`, {
      params: {
        Keywords,
        PageIndex,
        PageSize,
      },
    }),
});

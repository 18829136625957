import React, { useCallback, useState } from "react";

import Button from "../../../../components/common/Button";
import MerModal from "../../../../components/common/MerModal";
import { Icons } from "../../../../constants/icons";
import api from "../../../../api";
import { toast } from "react-toastify";
import moment from "moment";

const RequestProductModal = () => {
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [startDate, setStarDate] = useState(new Date());
  const [endDate, setEndDate] = useState();

  const onSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        e.stopPropagation();

        if (loading) return;
        setLoading(true);

        const sku = e.target.sku.value;

        if (sku.length <= 0) {
          toast.error("Please enter a value in the Sku field!");
          return;
        }
        const promotionStartDate = startDate
          ? moment.utc(startDate).subtract(1, "days").format("YYYY-MM-DD")
          : "";
        const promotionEndDate = endDate
          ? moment.utc(endDate).format("YYYY-MM-DD")
          : "";

        if (promotionStartDate && promotionEndDate) {
          const diff = moment(promotionEndDate).diff(
            moment(promotionStartDate),
            "days"
          );

          console.log(diff);

          if (diff > 100) {
            toast.error("Promotion date range cannot be more than 100 days");
            return;
          }
        }

        console.log(
          `sku : ${sku}, promotionStartDate : ${promotionStartDate}, promotionEndDate : ${promotionEndDate}`
        );
        await api.product.syncProductPriceBySky(
          sku,
          promotionStartDate,
          promotionEndDate
        );
        toast.success(
          <div>Product and prices should appear in 1-2 minute!</div>
        );
        setOpen(false);
      } catch (err) {
        toast.error(
          <div>
            {err.response?.data.message || err.message || "Unknown error"}
          </div>
        );
      } finally {
        setLoading(false);
      }
    },
    [endDate, loading, startDate]
  );

  return (
    <div>
      <Button
        className="create_promotion_button px-2"
        label="Sync Product by SKU"
        bindEvent={() => setOpen(true)}
        buttonIcon={Icons.nullIcon}
        iconWidth={16}
        iconHeight={16}
      />
      <MerModal
        id="request-product-modal"
        ariaLabelledby="contained-modal-title-vcenter"
        showModal={isOpen}
        onHide={() => setOpen(false)}
      >
        <div className="table-responsive-xl px-5 py-4 h-100">
          <form onSubmit={onSubmit}>
            <div className="d-flex flex-column gap-4">
              <div className="d-flex align-items-start gap-4">
                <div className="flex-grow-1">
                  <div className="d-flex flex-column user-input gap-2">
                    <label htmlFor="invoicePeriod" className="form-label">
                      Provider
                    </label>
                    <select disabled>
                      <option value="Genba">Genba</option>
                    </select>
                  </div>
                </div>
                <div className="flex-grow-1">
                  <div className="d-flex flex-column user-input gap-2">
                    <label htmlFor="invoicePeriod" className="form-label">
                      SKU
                    </label>
                    <input id="sku" type="string" name="sku" />
                  </div>
                </div>
              </div>
              <b className="text-danger">
                Please enter promotion dates for discount !!
              </b>
              <div className="d-flex align-items-start gap-4">
                <div className="flex-grow-1">
                  <div className="d-flex flex-column user-input gap-2">
                    <label htmlFor="invoicePeriod" className="form-label">
                      Promotion Start Date:
                    </label>
                    <input
                      className="form-control"
                      id="promotionStartDate"
                      name="promotionStartDate"
                      type="datetime-local"
                      onChange={(e) => {
                        if (e.target.value)
                          setStarDate(new Date(e.target.value));
                      }}
                    />
                  </div>
                </div>

                <div className="flex-grow-1">
                  <div className="d-flex flex-column user-input gap-2">
                    <label htmlFor="invoicePeriod" className="form-label">
                      Promotion End Date:
                    </label>
                    <input
                      className="form-control"
                      min={moment.utc(startDate).format("YYYY-MM-DDTHH:mm")}
                      id="promotionEndDate"
                      name="promotionEndDate"
                      type="datetime-local"
                      onChange={(e) => {
                        if (e.target.value)
                          setEndDate(new Date(e.target.value));
                      }}
                    />
                  </div>
                </div>
              </div>
              <button
                disabled={loading}
                className="btn btn-success"
                type="submit"
              >
                Sync
              </button>
            </div>
          </form>
        </div>
      </MerModal>
    </div>
  );
};

export default RequestProductModal;

/***
 * COMMON
 */
export const APP_LOAD = `"APP_LOAD"`;
export const REDIRECT = `"REDIRECT"`;
export const RETURN_URL = `"RETURN_URL"`;
export const FILTER_LOADER = `"FILTER_LOADER"`;
export const MENU_STATUS_CHANGE = `"MENU_STATUS_CHANGE"`;
export const UPDATE_FIELD_COMMON = `"UPDATE_FIELD_COMMON"`;
export const SELECTED_PAGESIZE = `"SELECTED_PAGESIZE"`;

/**
 * AUTH
 * */
export const LOGIN = `"LOGIN"`;
export const LOGOUT = `"LOGOUT"`;
export const REGISTER = `"REGISTER"`;
export const UPDATE_FIELD_AUTH = `"UPDATE_FIELD_AUTH"`;

/**
 * PRODUCT
 */
export const GET_PRODUCT = `"GET_PRODUCT"`;
export const CHANGE_PRICE_DETAILS_BASKET = `"CHANGE_PRICE_DETAILS_BASKET"`;
export const ADD_TO_BASKET = `"ADD_TO_BASKET"`;
export const GET_BASKET = `"GET_BASKET"`;
export const SELECT_PRODUCT = `"SELECT_PRODUCT"`;
export const SET_BASKET_DETAILS = `"SET_BASKET_DETAILS"`;
export const SELECT_BASKET_ITEM = `"SELECT_BASKET_ITEM"`;
/**
 * Orders and Offers
 */
export const GET_ORDERS = `"GET_ORDERS"`;
export const GET_ORDER = `"GET_ORDER"`;
export const ORDER_LIST = `"ORDER_LIST"`;
export const SELECT_OFFER_ALL_MY_OFFERS = `"SELECT_OFFER_ALL_MY_OFFERS"`;
export const CHANGE_ALL_MY_OFFERS_DETAILS = `"CHANGE_ALL_MY_OFFERS_DETAILS"`;
export const SELECT_ALL_OFFERS = `"SELECT_ALL_OFFER"`;
export const GET_ORDER_ITEMS = "GET_ORDER_ITEMS";
export const REFRESH_ITEM = `"REFRESH_ITEM"`;
export const MANAGEMENT_INVENTORIES_PAGE_LOADED = `"MANAGEMENT_INVENTORIES_PAGE_LOADED"`;
export const MANUEL_STOCK_PAGE_DETAILS = `"MANUEL_STOCK_PAGE_DETAILS"`;
export const GET_ORDER_BLENDING = `"GET_ORDER_BLENDING"`;

/**
 * MANAGEMENT - REPORT
 */
export const REPORT_ORDER_ITEMS = "REPORT_ORDER_ITEMS";
export const REPORT_ORDER_ITEMS_COUNT_BY_STATE =
  "REPORT_ORDER_ITEMS_COUNT_BY_STATE";
export const REPORT_TOP_SELLERS_PAGE_LOADED = `"REPORT_TOP_SELLERS_PAGE_LOADED"`;
export const REPORT_PRODUCT_REPORT_PAGE_LOADED = `"REPORT_PRODUCT_REPORT_PAGE_LOADED"`;
export const INVENTORY_REPORTS_LOADED = `"INVENTORY_REPORTS_LOADED"`;
export const INVENTORY_REPORT_LOADED = `"INVENTORY_REPORT_LOADED"`;
export const REPORT_MONTHLY_REVENUE_PAGE_LOADED = `"REPORT_MONTHLY_REVENUE_PAGE_LOADED"`;
export const REPORT_ORDER_PROCESS_BY_STATUS = `"REPORT_ORDER_PROCESS_BY_STATUS"`;
/**
 * MANAGEMENT - PRODUCT
 */
export const MANAGEMENT_GET_PRODUCT = `"MANAGEMENT_GET_PRODUCT"`;
export const CHANGE_PRICE_VALUE = `"CHANGE_PRICE_VALUE"`;
export const SAVE_PRICE = `"SAVE_PRICE"`;

/**
 * MANAGEMENT - USER
 */
export const USERS_SEARCH_ONCHANGE = `"USERS_SEARCH_ONCHANGE"`;
export const USERS_PAGE_LOADED = `"USERS_PAGE_LOADED"`;
export const CREATE_USER = `"CREATE_USER"`;
export const SELECTED_UPDATE_USER = `"SELECTED_UPDATE_USER"`;

/**
 * REGIONS
 */

export const GET_REGIONS = `"GET_REGIONS"`;

/**
 * PROMOTION
 */
export const PROMOTIONS_LIST_PARTNER_PAGE_LOADED = `"PROMOTIONS_LIST_PARTNER_PAGE_LOADED"`;
export const SELECT_PROMOTION = `"SELECT_PROMOTION"`;
export const PROMOTION_DETAIL = `"PROMOTION_DETAIL"`;
export const MANAGEMENT_PROMOTION_PAGE_LOADED = `"MANAGEMENT_PROMOTION_PAGE_LOADED"`;
export const MANAGEMENT_CREATE_PROMOTION_PRODUCT = `"MANAGEMENT_CREATE_PROMOTION_PRODUCT"`;
export const PROMOTION_PRODUCT_SELECT = `"PROMOTION_PRODUCT_SELECT"`;
export const MANAGEMENT_PROMOTION_UPDATE_FIELD = `"MANAGEMENT_PROMOTION_UPDATE_FIELD"`;
export const MANAGEMENT_SELECTED_REGION_FIELD = `"MANAGEMENT_SELECTED_REGION_FIELD"`;
export const MANAGEMENT_SELECTED_MERKUR_REGION_FIELD = `"MANAGEMENT_SELECTED_MERKUR_REGION_FIELD"`;
export const UPCOMING_PROMOTION = `"UPCOMING_PROMOTION"`;

/**
 * OFFERS
 */
export const OFFER_DETAILS = `"OFFER_DETAILS"`;

/**
 * FILTERS
 */
export const PUBLISHER_ON_LOAD = `"PUBLISHER_ON_LOAD"`;
export const PROVIDER_ON_LOAD = `"PROVIDER_ON_LOAD"`;
export const FILTER_DETAILS = `"FILTER_DETAILS"`;
export const STORE_ON_LOAD = `"STORE_ON_LOAD"`;
export const PARTNER_ON_LOAD = `"PARTNER_ON_LOAD"`;

/**
 * INVIOCE
 */
export const MANAGEMENT_INVIOCE_PAGE_LOADED = `"MANAGEMENT_INVIOCE_PAGE_LOADED"`;
export const MANAGEMENT_CREDITNOTE_PAGE_LOADED = `"MANAGEMENT_CREDITNOTE_PAGE_LOADED"`;
export const PARTNER_INVIOCE_PAGE_LOADED = `"PARTNER_INVIOCE_PAGE_LOADED"`;

/**
 * VENDORS
 */
export const MANAGEMENT_VENDORS_PAGE_LOADED = `"MANAGEMENT_VENDORS_PAGE_LOADED"`;

/**
 * ALLOCATION
 */
export const MANAGEMENT_ALLOCATION_PAGE_LOADED = `"MANAGEMENT_ALLOCATION_PAGE_LOADED"`;

/**
 * COMPANY
 */
export const COMPANIES_PAGE_LOADED = `"COMPANIES_PAGE_LOADED"`;

export const PERMISSIONS_LOADED = `"PERMISSIONS_LOADED"`;
export const PERMISSIONS_CHANGED = `"PERMISSIONS_CHANGED"`;
export const PERMISSION_LOADED = `"PERMISSION_LOADED"`;
export const ALL_PERMISSION_LOADED = `"ALL_PERMISSION_LOADED"`;

import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { waitTimeOut } from "../../functions/FilterFunctions";
import api from "../../api";
import { toast } from "react-toastify";

const RegionFilter = ({ onFilter, hidden }) => {
  const [values, setValues] = useState(() => ({ keywords: null }));
  const [data, setData] = useState(() => ["CHINA", "ROW", "LATAM"]);
  const [selected, setSelected] = useState({});

  useEffect(() => {
    if (hidden) return;

    const fetchData = async () => {
      try {
        const res = await api.regions.getFilterable(values.keywords, 0, 10);
        setData(res || []);
      } catch (err) {
        toast.error(<div>{JSON.parse(err.response.text).message}</div>);
      }
    };

    fetchData();
  }, [hidden, values]);

  if (hidden) return null;

  return (
    <div className="filter-publisher d-block">
      <div className="btn-group pe-2 pt-1 rounded-1 align-items-center">
        <button
          type="button"
          className="btn btn-transparent shadow-none dropdown-toggle mr-2 dropdown-toggle-split "
          data-bs-toggle="dropdown"
          aria-expanded="false"
          data-bs-auto-close="outside"
          id="publishers"
          style={
            selected.length > 0 ? { color: "#264EA7" } : { color: "#A4B9E8" }
          }
        >
          <span className="mx-1 text">Region</span>
          <span className="badge">{selected.length || ""}</span>
        </button>

        <div className="dropdown-menu border-0 p-1 py-1">
          <div className="input-group flex-nowrap align-items-center search px-4 pt-3">
            <input
              onChange={(e) => {
                const keywords = e.target.value;

                waitTimeOut(() => {
                  setValues((prev) => ({ ...prev, keywords }));
                });
              }}
              type="text"
              className="form-control bg-white border-0 rounded-0 shadow-none "
              placeholder="Search"
              aria-label="Publisher"
              aria-describedby="addon-wrapping"
              id="publisher-filter-search"
            />
          </div>
          <div className="px-3 py-3">
            {Object.keys(selected)
              .filter((e) => selected[e])
              .map((item) => {
                return (
                  <div className="form-check" key={item}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name={item}
                      id={item}
                      onChange={(e) => {
                        const { name, checked } = e.target;
                        setSelected((prev) => ({
                          ...prev,
                          [name]: checked,
                        }));
                      }}
                      checked={selected[item]}
                    />
                    <label
                      className="form-check-label fw-filter"
                      htmlFor={item}
                    >
                      {item}
                    </label>
                  </div>
                );
              })}
            {data
              .filter((e) => !selected[e])
              .map((item) => {
                return (
                  <div className="form-check" key={item}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name={item}
                      id={item}
                      onChange={(e) => {
                        const { name, checked } = e.target;
                        setSelected((prev) => ({
                          ...prev,
                          [name]: checked,
                        }));
                      }}
                      checked={selected[item]}
                    />
                    <label
                      className="form-check-label fw-filter"
                      htmlFor={item}
                    >
                      {item}
                    </label>
                  </div>
                );
              })}
            <div
              hidden={Object.values(selected).filter((e) => e).length < 1}
              className="m-2"
            >
              <button
                onClick={() => setSelected(() => ({}))}
                className="button filter-actions clear-all"
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegionFilter;
